import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Autocomplete,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { warehouseService } from '../services/warehouseService';
import { productService } from '../services/productService';
import { inventoryService } from '../services/inventoryService';
import { commonService } from '../services/commonService';
import useDebounce from '../hooks/useDebounce';
import useSessionStorage from '../hooks/useSessionStorage';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Validation schema
const schema = yup.object().shape({
  adjustmentDate: yup.date().required('Adjustment date is required'),
  warehouse: yup.object().required('Warehouse is required'),
  reason: yup.string().required('Reason is required'),
  items: yup.array().of(
    yup.object().shape({
      product: yup.object().required('Product is required'),
      actualQty: yup.number().required('Actual quantity is required'),
    })
  ).min(1, 'At least one item is required'),
});

const StockAdjustmentForm = () => {
  const [products, setProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inventories, setInventories] = useState([]);
  const [adjustNo, setAdjustNo] = useState('');
  const [warehouses, setWarehouses] = useSessionStorage('warehouses', []);
  const debouncedInputValue = useDebounce(inputValue, 300);
  const MIN_SEARCH_CHARS = 3;

  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      items: [],
      adjustmentDate: dayjs(),
      warehouse: null,
      reason: '',
    }
  });

  const selectedWarehouse = watch('warehouse');
  const selectedItems = watch('items');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect( () => {
    fetchInputProducts();
    },[debouncedInputValue]);

    const fetchInputProducts = async () => {
      if (debouncedInputValue.length >= MIN_SEARCH_CHARS) {
        try {
          const data = await productService.searchProducts(debouncedInputValue);
          const formattedData = data.map(product => ({
            ...product
          }));
          setProducts(formattedData);
          console.log("Formatted data is: ",formattedData);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const nextNumber = await commonService.getNextTransactionNumber('STOCK_ADJUSTMENT');
          setAdjustNo(nextNumber);
          let warehousesData = warehouses;
          if (warehouses.length === 0) {
            warehousesData = await warehouseService.fetchAllWarehouses();
            setWarehouses(warehousesData);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, []);

    const handleProductChange = async (value, index) => {
      try {
        if (value && selectedWarehouse) {
          const inventory = await inventoryService.fetchInventoryByProduct(value.id);
          setInventories(inventory);

          // Find the inventory for the selected warehouse
          const selectedInventory = inventory.find(inv => inv.warehouse.id === selectedWarehouse.id);
          console.log("Selected inventory is: ",selectedInventory);
          // Update the current quantity and calculate the difference for the specific item
          const newItems = [...selectedItems];
          const currentQty = selectedInventory ? selectedInventory.quantity_on_hand : 0;
          newItems[index] = {
            ...newItems[index],
            currentQty: currentQty
          };
          // Update the form state
          setValue('items', newItems);
          console.log("New items are: ",newItems);
        } else {
          console.log("Else?");
        }
      } catch (error) {
        console.error('Error fetching inventory: ', error);
      }
    };

  const handleOpenConfirmDialog = (data) => {
    setFormData(data);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const onSubmit = async (data) => {
    handleOpenConfirmDialog(data);
  };

  const handleConfirmedSubmit = async () => {
    try {
      const adjustmentData = {
        warehouse: formData.warehouse.id,
        adjustment_type: "adj",
        reason: formData.reason,
        user: 1,
        adjustment_date: formData.adjustmentDate,
        items: formData.items.map(item => ({
          product: item.product.id,
          quantity: item.difference,
          average_price: 0,
          before_quantity: item.currentQty,
          after_quantity: item.actualQty
        }))
      };
      const result = await inventoryService.createAdjustment(adjustmentData);
      if (result) {
        await commonService.incrementTransactionNumber('STOCK_ADJUSTMENT');
        navigate('/warehouse');
        console.log('Stock adjustment created:', result);
      } else {
        console.error('Failed to create stock adjustment:', result);
      }
    } catch (error) {
      console.error('Error creating stock adjustment:', error);
    }
    handleCloseConfirmDialog();
  };

  const calculateDifference = (index) => {
    const items = watch('items');
    const currentItem = items[index];
    if (currentItem && currentItem.currentQty !== undefined && currentItem.actualQty !== undefined) {
      const difference = currentItem.actualQty - currentItem.currentQty;
      setValue(`items.${index}.difference`, difference);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Stock Adjustment Form #{adjustNo}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="adjustmentDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Adjustment Date"
                    {...field}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.adjustmentDate,
                        helperText: errors.adjustmentDate?.message
                      }
                    }}
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Controller
                name="warehouse"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={warehouses}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Warehouse"
                        error={!!errors.warehouse}
                        helperText={errors.warehouse?.message}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value)}
                    isOptionEqualToValue={(option, value) => "A" === "A"}
                    value={field.value}
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Reason"
                    fullWidth
                    error={!!errors.reason}
                    helperText={errors.reason?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">Product</TableCell>
                <TableCell width="15%">Current Qty</TableCell>
                <TableCell width="15%">Actual Qty</TableCell>
                <TableCell width="15%">Difference</TableCell>
                <TableCell width="15%">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Controller
                      name={`items.${index}.product`}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={products}
                          getOptionLabel={(option) => `${option.sku} - ${option.name}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!errors.items?.[index]?.product}
                            />
                          )}
                          onInputChange={(_, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleProductChange(value, index);
                          }}
                          isOptionEqualToValue={(option, value) => "A" === "A"}
                          value={field.value}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={selectedItems[index]?.currentQty || 0}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`items.${index}.actualQty`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          error={!!errors.items?.[index]?.actualQty}
                          onChange={(e) => {
                            field.onChange(e);
                            calculateDifference(index);
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`items.${index}.difference`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          onClick={() => append({ product: null, actualQty: '' })}
          sx={{ mt: 2, mr: 1 }}
        >
          Add Item
        </Button>
        
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </form>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Stock Adjustment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit this stock adjustment? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleConfirmedSubmit} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default StockAdjustmentForm;
