import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { createExpenseColumns } from '../components/ExpenseDashboard/expenseColumns';
import ExpenseForm from '../components/ExpenseDashboard/ExpenseForm';
import { expenseService } from '../services/expenseService';
  
function ExpenseDashboard() {
  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [newExpense, setNewExpense] = useState({
    date: new Date().toISOString().split('T')[0],
    category: null,
    amount: '',
    description: '',
    account: null
  });
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editMode, setEditMode] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const response = await expenseService.fetchExpenses(paginationModel.page + 1, paginationModel.pageSize);
      console.log("Response of expense is: ", response);
      setExpenses(response.results);
      setTotalCount(response.totalCount);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch expenses', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
    fetchCategories();
    fetchAccounts();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await expenseService.fetchAccounts();
      console.log("Categories is: ",data);
      setCategories(data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch categories', severity: 'error' });
    }
  };

  const fetchAccounts = async () => {
    try {
      const data = await expenseService.fetchAccounts();
      console.log("Account data  is: ",data);
      setAccounts(data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch accounts', severity: 'error' });
    }
  };

  // const calculateTotalExpenses = () => {
  //   const thirtyDaysAgo = new Date();
  //   thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    
  //   return expenses
  //     .filter(expense => new Date(expense.date) >= thirtyDaysAgo)
  //     .reduce((total, expense) => {
  //       const amount = typeof expense.amount === 'number' ? expense.amount : parseFloat(expense.amount.replace(/[^\d.-]/g, ''));
  //     }, 0);
  // };

  // const totalExpenses = calculateTotalExpenses();

  const handleOpen = () => {
    setEditMode(false);
    setEditingExpense(null);
    setNewExpense({
      date: new Date().toISOString().split('T')[0],
      category: null,
      amount: '',
      description: '',
      account: null
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleAddOrUpdateExpense = async () => {
    try {
      if (!newExpense.date || !newExpense.category || isNaN(parseFloat(newExpense.amount)) || !newExpense.account) {
        throw new Error('Invalid expense data');
      }

      const expenseData = { 
        ...newExpense, 
        amount: parseFloat(newExpense.amount.replace(/[^\d.-]/g, '')),
        category: newExpense.category?.id,  
        account: newExpense.account?.id,    
        user: 1,
      };
      console.log("Expense data", expenseData);
      if (editMode) {
        await expenseService.updateExpense(editingExpense.id, expenseData);
        setSnackbar({ open: true, message: 'Expense updated successfully', severity: 'success' });
      } else {
        await expenseService.addExpense(expenseData);
        setSnackbar({ open: true, message: 'Expense added successfully', severity: 'success' });
      }
      
      handleClose();
      fetchExpenses();
    } catch (error) {
      setSnackbar({ open: true, message: `Failed to ${editMode ? 'update' : 'add'} expense: ` + error.message, severity: 'error' });
    }
  };

  const handleDeleteConfirm = (id) => {
    setExpenseToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setExpenseToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (expenseToDelete) {
      try {
        await expenseService.deleteExpense(expenseToDelete);
        setSnackbar({ open: true, message: 'Expense deleted successfully', severity: 'success' });
        fetchExpenses();
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete expense', severity: 'error' });
      }
    }
    setDeleteConfirmOpen(false);
    setExpenseToDelete(null);
  };

  const handleUpdate = (expense) => {
    setEditMode(true);
    setEditingExpense(expense);
    
    // Find the full category and account objects
    const category = categories.find(cat => cat.id === expense.category);
    const account = accounts.find(acc => acc.id === expense.account);
  
    setNewExpense({
      date: expense.date,
      category: category || null,
      amount: expense.amount.toString(),
      description: expense.description,
      account: account || null
    });
    setOpen(true);
  };

  const columns = createExpenseColumns(handleUpdate, handleDeleteConfirm);

  return (
    <Container>
      {/* <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: 2, mb: 3 }}>
        <InfoCard
          title="Expenses in 30 Days"
          value={`Rp ${totalExpenses}`}
          subtitle="Total from all categories"
          icon={CreditCardIcon}
        />
      </Box> */}

      <Button variant="outlined" color="primary" onClick={handleOpen} style={{ margin: '20px 0' }}>
        New Expense
      </Button>

      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={expenses}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25]}
          rowCount={totalCount}
          paginationMode="server"
          loading={loading}
          disableSelectionOnClick
        />
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Update Expense' : 'Add New Expense'}</DialogTitle>
        <ExpenseForm
          expense={newExpense}
          setExpense={setNewExpense}
          categories={categories}
          accounts={accounts}
          handleClose={handleClose}
          handleSubmit={handleAddOrUpdateExpense}
          loading={loading}
          editMode={editMode}
        />
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this expense?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDeleteCancel} disabled={loading}>Cancel</Button>
          <Button variant="outlined" onClick={handleDeleteConfirmed} autoFocus disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ExpenseDashboard;