import React, { useState, useMemo } from 'react';
import { 
    Box,
    TextField,
    Typography,
    Paper,
    Button
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';

const WholesaleForm = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([
        // Sample data - replace with actual data
        {
            id: 1,
            sku: 'SKU001',
            name: 'Product 1',
            variation: 'Red',
            wholesalePrice: 30000,
            productLink: 'https://example.com/product1',
            quantity: 1
        },
        {
            id: 2,
            sku: 'SKU001',
            name: 'Product 1',
            variation: 'Red',
            wholesalePrice: 30000,
            productLink: 'https://example.com/product1',
            quantity: 1
        },
        {
            id: 3,
            sku: 'SKU003',
            name: 'Product 3',
            variation: 'Blue',
            wholesalePrice: 30000,
            productLink: 'https://example.com/product3',
            quantity: 1
        },      
        {
            id: 4,
            sku: 'SKU004',
            name: 'Product 4',
            variation: 'Green',
            wholesalePrice: 30000,
            productLink: 'https://example.com/product4',
            quantity: 1
        },
        {
            id: 5,
            sku: 'SKU005',
            name: 'Product 5',
            variation: 'Yellow',
            wholesalePrice: 30000,
            productLink: 'https://example.com/product5',
            quantity: 1
        },

    
        // Add more products as needed
    ]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleQuantityChange = (id, newQuantity) => {
        setProducts(prevProducts => prevProducts.map(product => 
            product.id === id 
                ? {...product, quantity: parseInt(newQuantity) || 0}
                : product
        ));
    };

    const filteredProducts = products.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sku.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = useMemo(() => [
        { field: 'sku', headerName: 'SKU', width: 120 },
        { field: 'name', headerName: 'Product Name', width: 200 },
        { field: 'variation', headerName: 'Variation', width: 120 },
        { 
            field: 'wholesalePrice', 
            headerName: 'Wholesale Price', 
            width: 130
        },
        { 
            field: 'productLink', 
            headerName: 'Product Link',     
            width: 200,
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer">
                    View Product
                </a>
            )
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 130,
            renderCell: (params) => (
                <TextField
                    type="number"
                    value={params.value}
                    onChange={(e) => handleQuantityChange(params.row.id, e.target.value)}
                    size="small"
                    inputProps={{ min: 0 }}
                />
            )
        },
        {
            field: 'total',
            headerName: 'Total', 
            width: 130,
            valueGetter: (params) => {
                if (!params || !params.row) return 0;
                const quantity = params.row.quantity || 0;
                const price = params.row.wholesalePrice || 0;
                return quantity * price;
            },
            renderCell: (params) => {
                const quantity = params.row.quantity || 0;
                const price = params.row.wholesalePrice || 0;
                return `Rp ${quantity * price}`;
            }
        }
    ], [products]);

    const subtotal = products.reduce((sum, product) => 
        sum + (product.quantity * product.wholesalePrice), 0
    );

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                <FormattedMessage id="wholesale.form.title" defaultMessage="Wholesale Order Form" />
            </Typography>

            <TextField
                label="Search products by name or SKU"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ mb: 2 }}
            />

            <Paper sx={{ width: '100%', mb: 2 }}>
                <DataGrid
                    rows={filteredProducts}
                    columns={columns}
                    autoHeight
                    disableSelectionOnClick
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Typography variant="h6">
                    Subtotal: Rp {subtotal}
                </Typography>
            </Box>
        </Box>
    );
};

export default WholesaleForm;
