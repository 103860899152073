import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { orderService } from '../services/orderService';
import { formatCurrency } from '../utils/currencyUtils';

const SalesDetailView = () => {
  // console.log('SalesDetailView component rendered');

  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // console.log('Initial state:', { id, order, isLoading });

  useEffect(() => {
    // console.log('useEffect triggered with id:', id);

    const fetchOrder = async () => {
      // console.log('Fetching order data...');
      setIsLoading(true);
      try {
        // console.log('Calling orderService.fetchOrderDetails with id:', id);
        const orderData = await orderService.fetchOrderDetails(id);
        // console.log('Received order data:', orderData);
        setOrder(orderData);
      } catch (error) {
        console.error('Error fetching order:', error);
        // Handle error (e.g., show error message)
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  const handleEdit = () => {
    navigate(`/sales/${id}/edit`);
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      try {
        await orderService.deleteOrder(id);
        navigate('/sales');
      } catch (error) {
        console.error('Error deleting order:', error);
        // Handle error (e.g., show error message)
      }
    }
  };

  // console.log('Current state before render:', { order, isLoading });

  if (isLoading) {
    // console.log('Rendering loading state');
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!order) {
    console.log('Order not found, rendering error message');
    return <Typography>Order not found.</Typography>;
  }

  // console.log('Rendering order details:', order);

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 3, my: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4" component="h1">
            Sales Order Details
          </Typography>
          <Box>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              color="primary"
              onClick={handleEdit}
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="outlined"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Order Number:</strong> {order.order_number}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Order Date:</strong> {new Date(order.order_date).toLocaleDateString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Customer:</strong> {order.customer_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Warehouse:</strong> {order.warehouse_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Status:</strong> {order.status}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Total:</strong> {formatCurrency(order.total)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Memo:</strong> {order.memo}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
          Order Items
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
  
                        {`${item.product_sku} - ${item.product_name}`}
                      
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{formatCurrency(item.price)}</TableCell>
                    <TableCell>{formatCurrency(item.quantity * item.price)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default SalesDetailView;